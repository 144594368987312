// src/components/Login.tsx
import React from "react";
import { Typography, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import AuthPageWrapperComponent from "../../Components/AuthenticationComponents/AuthPageWrapperComponent";
import { NavLink, useNavigate } from "react-router-dom";
import { login } from "../../reducers/auth_reducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { login as loginService } from "../../services/auth_service";
import { AppLogo } from "../../Helpers/constants";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // Styles for fixed height and border radius
    height: "400px", // Adjust the height as needed
    width: "100%",
    borderRadius: "20px",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    alignItems: "center",
    justifyContent: "flex-start",
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: "150px",
    height: "auto",
  },
  titleDiv: {
    width: "100%",
    textAlign: "center",
    marginBottom: theme.spacing(1),
    fontSize: "2rem",
    fontWeight: "bold", // Title made more bold
    letterSpacing: "1px",
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    fontSize: "1.2rem",
    color: "#555",
    textAlign: "center",
  },
  form: {
    width: "100%", // Form width
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#4D67EB",
  },
  errorMessage: {
    color: "red",
    fontSize: "12px",
  },
  linkLabels: {
    color: "#4D67EB",
    cursor: "pointer",
    textDecoration: "none",
  },
}));

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string().required("Required"),
});

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values: any) => {
    try {
      const response = await loginService(values.email, values.password);
      if (response?.status?.status !== "error") {
        dispatch(
          login({
            token: response.jwt,
            user: { full_name: response.full_name },
          })
        );
        localStorage.setItem("token", response.jwt);
        navigate("/events");
      } else {
        Swal.fire({
          title: "Error",
          text: response.status.message,
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: "Invalid credentials",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <AuthPageWrapperComponent withBack={false}>
      {/* AppLogo at the top */}
      <div style={{ width: "100%", textAlign: "center", marginTop: "-20px" }}>
        <AppLogo width={"100%"} />
      </div>
      <div className={classes.formContainer}>
        {/* Title */}
        <Typography component="h1" variant="h4" className={classes.titleDiv}>
          Welcome to eTicketing
        </Typography>
        {/* Subtitle */}
        <Typography
          component="h2"
          variant="subtitle1"
          className={classes.subtitle}
        >
          Login to your account
        </Typography>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className={classes.form}>
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                error={errors.email && touched.email ? true : false}
                autoFocus
              />
              <ErrorMessage
                name="email"
                component="div"
                className={classes.errorMessage}
              />
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={errors.password && touched.password ? true : false}
              />
              <ErrorMessage
                name="password"
                component="div"
                className={classes.errorMessage}
              />
              <Typography align="right">
                <NavLink to="/forgot_password" className={classes.linkLabels}>
                  Forgot Password?
                </NavLink>
              </Typography>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Sign In
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthPageWrapperComponent>
  );
};

export default LoginPage;
