import axios, { InternalAxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import store from "../store/store";

const dispatch = store.dispatch;
const noLoadingURLS = ["/logout", "/login"];

export const apiClient = axios.create({
    baseURL: process.env.REACT_APP_END_POINT + "/ticketapi",
});

const userInfo = {}; // Placeholder for user information

apiClient.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
        // Transform the request data into FormData
        if (config.data && !(config.data instanceof FormData)) {
            const formData = new FormData();
            Object.keys(config.data).forEach(key => {
                formData.append(key, config.data[key]);
            });
            config.data = formData;
            config.headers['Content-Type'] = 'multipart/form-data';
        }

        // Add authorization header if user information is available
        if (userInfo) {
            config.headers = config.headers || {};
            // const { access_token } = userInfo;
            // config.headers['Authorization'] = `Bearer ${access_token}`;
        }

        // Dispatch loader state unless the URL is excluded
        if (config.url && !noLoadingURLS.includes(config.url)) {
            // dispatch(setIsPending(true));
        }

        return config;
    },
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

apiClient.interceptors.response.use(
    (response: AxiosResponse) => {
        setTimeout(() => {
            // dispatch(setIsPending(false));
        }, 500);
        return response;
    },
    (error: AxiosError) => {
        setTimeout(() => {
            // dispatch(setIsPending(false));
        }, 500);
        if (error.response && error.response.status === 401) {
            // localStorage.removeItem(accessToken);
            window.location.reload();
        }
        return Promise.reject(error);
    }
);
