import React from "react";
import LoginPage from "./../Pages/LoginPage/LoginPage";
import ForgotPasswordPage from "../Pages/ForgotPasswordPage/ForgotPasswordPage";
import EventsPage from "../Pages/EventsPage/EventsPage";
import EventsCreationPage from "../Pages/EventsPage/EventsCreationPage";
import HallsPage from "../Pages/Halls/HallsPage";
import HallsCreatePage from "../Pages/Halls/HallsCreatePage";
import SeatingPlansPage from "../Pages/SeatingPlansPage/SeatingPlansPage";
import CreateSeatingPlansPage from "../Pages/SeatingPlansPage/CreateSeatingPlansPage";
import OrderListPage from "../Pages/OrderListPage/OrderListPage";
import CustomerListPage from "../Pages/CustomerListPage/CustomerListPage";
import SalesManagementPage from "../Pages/SalesManagementPage/SalesManagementPage";
import EventDetailsPage from "../Pages/EventDetailsPage/EventDetailsPage";

interface Route {
  path: string;
  component: React.ComponentType<any>;
}

type RoutesArray = Route[];

export const routes_arr: RoutesArray = [
  {
    path: "/forgot_password",
    component: ForgotPasswordPage,
  },
  {
    path: "/events",
    component: EventsPage,
  },
  {
    path: "/events/create/:id?",
    component: EventsCreationPage,
  },
  {
    path: "/halls",
    component: HallsPage,
  },
  {
    path: "halls/create",
    component: HallsCreatePage,
  },

  {
    path: "/halls/edit/:id?",
    component: HallsCreatePage,
  },
  {
    path: "/seating-plans",
    component: SeatingPlansPage,
  },
  {
    path: "/seating-plans/create-seating-plan/:id?/:seating_id?",
    component: CreateSeatingPlansPage,
  },
  {
    path: "/events/details/:id", // Route for EventDetails
    component: EventDetailsPage,
  },
  {
    path: "/order-list",
    component: OrderListPage,
  },
  {
    path: "/customer-list",
    component: CustomerListPage,
  },
  {
    path: "/sales-management",
    component: SalesManagementPage,
  },
];

export const routes_arr_no_auth: RoutesArray = [
  {
    path: "/",
    component: LoginPage,
  },
  {
    path: "/forgot_password",
    component: ForgotPasswordPage,
  },
];
