import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
  makeStyles,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../store/store";
import { setCurrentHall, setHallsList } from "../../reducers/hall_reducer";
import halls_service from "../../services/halls_service";
import ReactCountryFlag from "react-country-flag";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    paddingBottom: theme.spacing(2),
  },
  container: {
    width: "98%",
    height: "95%",
    backgroundColor: "#fff",
    padding: "20px",
  },
  titlesContainer: {
    width: "98%",
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#4D67EB",
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3755a3",
    },
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  textField: {
    width: "100%",
  },
  selectField: {
    width: "32%",
  },
  addressContainer: {
    display: "flex",
    gap: theme.spacing(2),
  },
  loaderBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  languageTabs: {
    display: "flex",
    alignItems: "center",
  },
  errorText: {
    color: "#fff",
    backgroundColor: "rgba(255, 0, 0, 0.3)",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
  },
}));

type FormData = {
  hallName_en: string;
  hallName_am: string;
  hasSeatmap: boolean;
  country_en: string;
  country_am: string;
  city_en: string;
  city_am: string;
  street_en: string;
  street_am: string;
};

type Errors = {
  hallName_en: boolean;
  hallName_am: boolean;
  country_en: boolean;
  country_am: boolean;
  city_en: boolean;
  city_am: boolean;
  street_en: boolean;
  street_am: boolean;
};

const HallsCreatePage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>(); // Extracting id from URL
  const { hallsReducer } = useSelector((state: RootState) => state);
  const { hallsList } = hallsReducer;
  const [loading, setLoading] = useState(false); // Loader state
  const [language, setLanguage] = useState("en"); // Language state
  const [formData, setFormData] = useState<FormData>({
    hallName_en: "",
    hallName_am: "",
    hasSeatmap: false,
    country_en: "",
    country_am: "",
    city_en: "",
    city_am: "",
    street_en: "",
    street_am: "",
  });

  const [errors, setErrors] = useState<Errors>({
    hallName_en: false,
    hallName_am: false,
    country_en: false,
    country_am: false,
    city_en: false,
    city_am: false,
    street_en: false,
    street_am: false,
  });

  useEffect(() => {
    halls_service.getHallsList().then((res) => {
      const hallsList = res.data.status.halls;
      if (hallsList.length) {
        dispatch(setHallsList(hallsList));
      }

      if (id) {
        const currentHall = hallsList.find((hall: any) => hall.hall_id === id);
        if (currentHall) {
          setFormData({
            hallName_en: currentHall.hallName_en,
            hallName_am: currentHall.hallName_am,
            hasSeatmap: currentHall.hasSeatmap === "true",
            country_en: currentHall.country_en,
            country_am: currentHall.country_am,
            city_en: currentHall.city_en,
            city_am: currentHall.city_am,
            street_en: currentHall.street_en,
            street_am: currentHall.street_am,
          });
          dispatch(setCurrentHall(currentHall));
        }
      }
    });
  }, [id, dispatch]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = () => {
    setLoading(true); // Activate loader
    const newErrors: Errors = {
      hallName_en: formData.hallName_en === "",
      hallName_am: formData.hallName_am === "",
      country_en: formData.country_en === "",
      country_am: formData.country_am === "",
      city_en: formData.city_en === "",
      city_am: formData.city_am === "",
      street_en: formData.street_en === "",
      street_am: formData.street_am === "",
    };
    setErrors(newErrors);

    if (Object.values(newErrors).every((error) => !error)) {
      const hallData = {
        hallName_en: formData.hallName_en,
        hallName_am: formData.hallName_am,
        hasSeatmap: formData.hasSeatmap ? "true" : "false",
        country_en: formData.country_en,
        country_am: formData.country_am,
        city_en: formData.city_en,
        city_am: formData.city_am,
        street_en: formData.street_en,
        street_am: formData.street_am,
      };

      if (id) {
        // Update the hall if id exists
        halls_service
          .updateHall({ hall_id: id, ...hallData })
          .then((res) => {
            setLoading(false); // Deactivate loader
            if (res.status === 200) {
              toast.success("Hall updated successfully");
              navigate("/halls");
            } else {
              toast.error("Failed to update hall");
            }
          });
      } else {
        // Create a new hall if no id exists
        halls_service.createHall(hallData).then((res) => {
          setLoading(false); // Deactivate loader
          if (res.status === 200) {
            toast.success("Hall created successfully");
            navigate("/halls");
          } else {
            toast.error("Failed to create hall");
          }
        });
      }
    } else {
      setLoading(false); // Deactivate loader if there are validation errors
    }
  };

  return (
    <Box className={classes.root}>
      <ToastContainer />
      <Backdrop className={classes.loaderBackdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.container}>
        <Box className={classes.buttonContainer}>
          <Typography variant="body1">{id ? "Edit Hall" : "Add New Hall"}</Typography>
          <AddEventButton handleSubmit={handleSubmit} />
        </Box>
        <Box className={classes.languageTabs}>
          <Tabs
            value={language}
            onChange={(e, newValue) => setLanguage(newValue)}
            aria-label="language tabs"
          >
            <Tab
              icon={<ReactCountryFlag countryCode="US" svg style={{ marginRight: 10 }} />}
              value="en"
              label="English"
            />
            <Tab
              icon={<ReactCountryFlag countryCode="AM" svg style={{ marginRight: 10 }} />}
              value="am"
              label="Armenian"
            />
          </Tabs>
        </Box>
        <Box className={classes.formContainer}>
          {(errors.hallName_en || errors.hallName_am || errors.country_en || errors.country_am || errors.city_en || errors.city_am || errors.street_en || errors.street_am) && (
            <Typography className={classes.errorText}>
              Please fill out all required fields in all languages.
            </Typography>
          )}
          <Typography variant="body1">{language === "en" ? "Hall Name (English)" : "Սրահի անունը (Հայերեն)"}</Typography>
          <TextField
            placeholder={language === "en" ? "Hall Name" : "Սրահի անունը"}
            variant="outlined"
            className={classes.textField}
            name={`hallName_${language}`}
            value={formData[`hallName_${language}` as keyof FormData]}
            onChange={handleChange}
            required
            error={errors[`hallName_${language}` as keyof Errors]}
            helperText={errors[`hallName_${language}` as keyof Errors] && (language === "en" ? "Hall Name is required" : "Սրահի անունը պարտադիր է")}
          />
          <FormControlLabel
            control={
              <Checkbox
                name="hasSeatmap"
                color="primary"
                checked={formData.hasSeatmap}
                onChange={handleChange}
              />
            }
            label="Has seatmap"
            style={{
              margin: "50px 0",
            }}
          />
          <Typography variant="body1">Address</Typography>
          <Box className={classes.addressContainer}>
            <TextField
              placeholder={language === "en" ? "Country" : "Երկիր"}
              variant="outlined"
              className={classes.selectField}
              name={`country_${language}`}
              value={formData[`country_${language}` as keyof FormData]}
              onChange={handleChange}
              required
              error={errors[`country_${language}` as keyof Errors]}
              helperText={errors[`country_${language}` as keyof Errors] && (language === "en" ? "Country is required" : "Երկիրը պարտադիր է")}
            />
            <TextField
              placeholder={language === "en" ? "City" : "Քաղաք"}
              variant="outlined"
              className={classes.selectField}
              name={`city_${language}`}
              value={formData[`city_${language}` as keyof FormData]}
              onChange={handleChange}
              required
              error={errors[`city_${language}` as keyof Errors]}
              helperText={errors[`city_${language}` as keyof Errors] && (language === "en" ? "City is required" : "Քաղաքը պարտադիր է")}
            />
            <TextField
              placeholder={language === "en" ? "Street / Building" : "Փողոց / Շենք"}
              variant="outlined"
              className={classes.selectField}
              name={`street_${language}`}
              value={formData[`street_${language}` as keyof FormData]}
              onChange={handleChange}
              required
              error={errors[`street_${language}` as keyof Errors]}
              helperText={errors[`street_${language}` as keyof Errors] && (language === "en" ? "Street is required" : "Փողոցը պարտադիր է")}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const AddEventButton: React.FC<{ handleSubmit: () => void }> = ({
  handleSubmit,
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.addButton}
      size="small"
      onClick={handleSubmit}
    >
      Save
    </Button>
  );
};

export default HallsCreatePage;