import { useEffect } from "react";
import CustomerListTable from "../../Components/CustomerListComponents/CustomerListComponents";
import sales_management_service from "../../services/sales_management_service";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { setOrdersList } from "../../reducers/sales_management_reducer";

const CustomerListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { ordersList } = useSelector(
    (state: RootState) => state?.salesManagementReducer
  );
  useEffect(() => {
    if (ordersList.length === 0)
      sales_management_service.getOrdersListList().then((res) => {
        dispatch(setOrdersList(res.data.orders));
      });
  }, []);
  return <CustomerListTable />;
};

export default CustomerListPage;
