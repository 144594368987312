import React from "react";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { use } from "i18next";
import { useTranslation } from "react-i18next";

// Define the interface for event data
interface EventData {
  event_id: string;
  name: string;
  location: string;
  soldTickets: number;
  image: string;
  percentage: number;
}

// Colors for the pie chart slices
const COLORS = ["#4D67EB", "#94A8F9", "#788EF3", "#5DADE2", "#58D68D"];

// Styles for the percentage text in the center of the pie chart
const centerStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center" as "center",
};

// Styled Paper component to remove box shadow and add padding
const StyledPaper = (props: any) => (
  <Paper
    elevation={0}
    sx={{
      padding: { xs: 2, sm: 4 },
      backgroundColor: "#FFFFFF",
      position: "relative",
      borderRadius: 2,
      boxShadow: 1,
    }}
    {...props}
  />
);

const SalesManagementComponent: React.FC = () => {
  const theme = useTheme(); // Access MUI theme for responsive design
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // Determine if screen is small
  const { t } = useTranslation();
  // Get ordersList from Redux state
  const { ordersList } = useSelector(
    (state: RootState) => state?.salesManagementReducer
  );

  // Process ordersList to generate data for the pie chart and summary list
  const eventDataMap: { [key: string]: EventData } = {};

  // Total sold tickets across all events
  let totalSoldTickets = 0;

  ordersList.forEach((order: any) => {
    const eventId = order.event_id;
    const soldTickets = Number(order.sold_ticket) || 0;
    totalSoldTickets += soldTickets;

    if (!eventDataMap[eventId]) {
      eventDataMap[eventId] = {
        event_id: eventId,
        name: order.eventName_en || "Unknown Event",
        location: order.location || "Unknown Location",
        soldTickets: 0,
        image: order.eventImage || "https://via.placeholder.com/40", // Use event_image if available
        percentage: 0, // Will calculate later
      };
    }

    // Accumulate sold tickets per event
    eventDataMap[eventId].soldTickets += soldTickets;
  });

  // Convert eventDataMap to array and calculate percentages
  const eventDataArray = Object.values(eventDataMap);

  // Avoid division by zero
  if (totalSoldTickets === 0) {
    totalSoldTickets = 1;
  }

  // Calculate percentage for each event
  eventDataArray.forEach((event) => {
    event.percentage = Number(
      ((event.soldTickets / totalSoldTickets) * 100).toFixed(2)
    );
  });

  // Data for the pie chart
  const pieData = eventDataArray.map((event) => ({
    name: event.name,
    value: event.soldTickets,
  }));

  // Sort eventDataArray by soldTickets in descending order for the summary list
  eventDataArray.sort((a, b) => b.soldTickets - a.soldTickets);

  return (
    <Box
      sx={{
        padding: "0 3vw",
        minHeight: "90vh",
        minWidth: "85vw",
        backgroundColor: "#f0f2f5",
      }}
    >
      {/* Title Section */}
      <Box>
        <Typography
          variant={isSmallScreen ? "h6" : "h5"}
          gutterBottom
          sx={{
            fontWeight: "bold",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          {t("Sales Statistics")}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          sx={{
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          {t("Summary of ticket sales by event")}
        </Typography>
      </Box>

      {/* Content Section */}
      <Box sx={{ marginTop: 4 }}>
        <StyledPaper>
          <Grid
            container
            spacing={4}
            alignItems="center"
            justifyContent="space-between"
            direction={isSmallScreen ? "column" : "row"}
          >
            {/* Pie Chart Section */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: isSmallScreen ? 300 : 450,
                }}
              >
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={pieData}
                      dataKey="value"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={isSmallScreen ? 100 : 140}
                      innerRadius={isSmallScreen ? 60 : 100}
                      paddingAngle={5}
                      cornerRadius={10}
                      labelLine={false}
                    >
                      {pieData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
                {/* Centered Total Tickets Sold */}
                <Box sx={centerStyle}>
                  <Typography
                    variant={isSmallScreen ? "h4" : "h3"}
                    component="div"
                    fontWeight="bold"
                  >
                    {totalSoldTickets}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary">
                    Total Tickets Sold
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* Summary List Section */}
            <Grid item xs={12} md={5}>
              <List>
                {eventDataArray.map((event, index) => (
                  <ListItem
                    key={event.event_id}
                    sx={{
                      marginBottom: 2,
                      paddingLeft: 0,
                      paddingRight: 0,
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      flexDirection: isSmallScreen ? "column" : "row",
                      alignItems: isSmallScreen ? "flex-start" : "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: isSmallScreen ? "100%" : "70%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <ListItemAvatar>
                          <Avatar
                            src={event.image}
                            alt={`${event.name} poster`}
                            sx={{
                              borderRadius: "8px",
                              width: 40,
                              height: 40,
                              mr: isSmallScreen ? 0 : 2,
                              mb: isSmallScreen ? 1 : 0,
                            }}
                          />
                        </ListItemAvatar>
                        <Box
                          sx={{
                            marginLeft: isSmallScreen ? 0 : 1,
                            textAlign: isSmallScreen ? "left" : "left",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{
                              fontSize: isSmallScreen ? "1rem" : "1.1rem",
                            }}
                          >
                            {event.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            sx={{
                              fontSize: isSmallScreen ? "0.8rem" : "0.9rem",
                            }}
                          >
                            {event.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="subtitle1"
                        color="#5483F9"
                        sx={{
                          fontSize: isSmallScreen ? "1rem" : "1.1rem",
                          marginLeft: isSmallScreen ? 2 : 0,
                          marginTop: isSmallScreen ? 1 : 0,
                        }}
                      >
                        {event.soldTickets} tickets
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </StyledPaper>
      </Box>
    </Box>
  );
};

export default SalesManagementComponent;
