// src/reducers/authReducer.ts
import { AuthState, User } from '../types';

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const authReducer = (state: AuthState = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'LOGIN':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', JSON.stringify(action.payload.user));
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        user: action.payload.user,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
      };
    case 'CHECK_AUTHENTICATION':
      const token = localStorage.getItem('token');
      const user = localStorage.getItem('user');
      if (token && user) {
        return {
          ...state,
          isAuthenticated: true,
          token: token,
          user: JSON.parse(user),
        };
      }
      return state;
    default:
      return state;
  }
};

export const login = (payload: { token: string, user: User }) => ({
  type: 'LOGIN' as const,
  payload,
});

export const logout = () => ({
  type: 'LOGOUT' as const,
});

export const checkAuthentication = () => ({
  type: 'CHECK_AUTHENTICATION' as const,
});

export type AuthAction = 
  | ReturnType<typeof login>
  | ReturnType<typeof logout>
  | ReturnType<typeof checkAuthentication>;

export default authReducer;