import React, { useState } from 'react';
import { Box, IconButton, Typography, Grid } from '@mui/material';
import { format, startOfWeek, addDays, subWeeks, addWeeks } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDay } from '../../reducers/event_reducer';
import { RootState } from '../../store/store';

// Define styles
const useStyles = makeStyles({
  root: {
    padding: '16px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px',
  },
  dayBox: {
    padding: '8px',
    textAlign: 'center',
    cursor: 'pointer',
    justifyContent: 'space-between',
  },
  selectedDay: {
    padding: '6px 10px',
    borderRadius: '25px',
    color: 'white',
    backgroundColor: '#4D67EB',
    fontSize: '25px',
    marginTop: '5px',
  },
  normalDay: {
    padding: '6px 10px',
    borderRadius: '15px',
    marginTop: '5px',
    color: 'black',
    fontSize: '16px',
    backgroundColor: 'transparent',
  },
});

const WeekCalendar: React.FC = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const selectedDay = useSelector((state: RootState) => state.eventsReducer.selectedDay);
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const startOfCurrentWeek = startOfWeek(currentDate, { weekStartsOn: 1 }); // Week starts on Monday

  const handlePrevWeek = () => {
    setCurrentDate(subWeeks(currentDate, 1));
  };

  const handleNextWeek = () => {
    setCurrentDate(addWeeks(currentDate, 1));
  };

  const handleDayClick = (day: Date) => {
    if (selectedDay === day.toISOString()) {
      dispatch(setSelectedDay(null));
    } else {
      setCurrentDate(day);
      dispatch(setSelectedDay(day.toISOString())); // Convert date to ISO string
    }
  };

  const renderWeekDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const day = addDays(startOfCurrentWeek, i);
      const isSelectedDay = selectedDay === day.toISOString();
      days.push(
        <Grid item xs={1} key={i} >
          <Box
            className={classes.dayBox}
            onClick={() => handleDayClick(day)}
          >
            <Typography variant="body1" style={{ color: '#BABABA', fontSize: '13px' }}>
              {t(`days.${format(day, 'EEEE')}`, format(day, 'EEE'))}
            </Typography>
            <Typography
              className={isSelectedDay ? classes.selectedDay : classes.normalDay}
            >
              {format(day, 'd')}
            </Typography>
          </Box>
        </Grid>
      );
    }
    return days;
  };

  const getTranslatedMonth = (date: Date) => {
    const month = format(date, 'MMMM'); // Get full month name with first letter capitalized
    return t(`months.${month}`, month);
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <IconButton onClick={handlePrevWeek} style={{ marginRight: '20px' }}>
          <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#151212" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.26 15.53L9.73999 12L13.26 8.46997" stroke="#151212" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </IconButton>
        <Typography variant="h6">
          {`${getTranslatedMonth(currentDate)} ${format(currentDate, 'yyyy')}`}
        </Typography>
        <IconButton onClick={handleNextWeek} style={{ marginLeft: '20px' }}>
          <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#191919" strokeWidth="1.25" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.74 15.53L14.26 12L10.74 8.46997" stroke="#191919" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </IconButton>
      </Box>
      <Grid container spacing={1} justifyContent="space-around">
        {renderWeekDays()}
      </Grid>
    </Box>
  );
};

export default WeekCalendar;