export const hallsActionTypes = {
  SET_HALLS_LIST: "SET-HALLS-LIST",
  SET_CURRENT_HALL: "SET-CURRENT-HALL",
};

// action_types.ts
export const eventsActionTypes = {
  SET_EVENTS_LIST: "SET_EVENTS_LIST",
  SET_CURRENT_EVENT: "SET-CURRENT-EVENT",
  SET_SELECTED_DAY: "SET-SELECTED-DAY",
};

export const salesManagementActionTypes = {
  SET_ORDERS_LIST: "SET-ORDERS-LIST"
};
