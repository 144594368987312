import { Event, EventsState } from "../types";
import { eventsActionTypes } from "./action_types";

const initialState: EventsState = {
  eventsList: [],
  currentEvent: null,
  selectedDay: null,
};

const { SET_EVENTS_LIST, SET_CURRENT_EVENT, SET_SELECTED_DAY } = eventsActionTypes;

const eventsReducer = (
  state: EventsState = initialState,
  action: EventsAction
): EventsState => {
  switch (action.type) {
    case SET_EVENTS_LIST:
      return {
        ...state,
        eventsList: action.payload.data,
      };
    case SET_CURRENT_EVENT:
      return {
        ...state,
        currentEvent: action.payload.data,
      };
    case SET_SELECTED_DAY:
      return {
        ...state,
        selectedDay: action.payload.data,
      };
    default:
      return state;
  }
};

export const setEventsList = (data: Event[]) => ({
  type: SET_EVENTS_LIST,
  payload: {
    data,
  },
});

export const setCurrentEvent = (data: any) => ({
  type: SET_CURRENT_EVENT,
  payload: {
    data,
  },
});

export const setSelectedDay = (data: string | null) => ({
  type: SET_SELECTED_DAY,
  payload: {
    data,
  },
});

export type EventsAction =
  | ReturnType<typeof setEventsList>
  | ReturnType<typeof setCurrentEvent>
  | ReturnType<typeof setSelectedDay>;

export default eventsReducer;