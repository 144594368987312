// CustomerListTable.tsx

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  TablePagination,
  styled,
  IconButton,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import QRCode from "react-qr-code";
import MovieIcon from "@mui/icons-material/Movie";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import sales_management_service from "../../services/sales_management_service";
import { useTranslation } from "react-i18next";

// Define the Customer interface
interface Customer {
  id: string; // id is a string in your data
  purchaseDate: string;
  customerName: string;
  email: string;
  purchaseReceipt: string;
}

// Define the PurchaseDetail interface
interface PurchaseDetail {
  customer: string; // user_name
  salesId: string; // reserv_id
  movieName: string; // eventName_en or eventName_am
  movieSubtitle: string; // description_en or description_am
  movieImage: string; // eventImage
  tickets: string[]; // tickets.split(',')
  ticketCount: number; // Number(sold_ticket)
  time: string; // dateTime.split(' ')[1]
  date: string; // dateTime.split(' ')[0]
  qr_url: string; // qr_url
}

// Styled Back Button with only back arrow icon
const BackButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: "#4D67EB",
  color: "#FFFFFF",
  padding: theme.spacing(1),
  "&:hover": {
    backgroundColor: "#3a54c6",
  },
}));

// Container for the component with enhanced padding
const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#FFFFFF",
  width: "100%",
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
}));

// Styled Ticket Box
const TicketBox = styled(Box)(({ theme }) => ({
  border: "1px solid #ccc",
  borderRadius: "8px",
  padding: theme.spacing(1, 2),
  display: "inline-block",
  marginRight: theme.spacing(1),
}));

// Component to display selected customer purchase details
const SelectedCustomers: React.FC<{
  selected: Customer[];
  onBack: () => void;
}> = ({ selected, onBack }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [purchaseDetails, setPurchaseDetails] = useState<PurchaseDetail[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const language = i18n.language;

  // Fetch purchase details for selected customers
  useEffect(() => {
   
const fetchPurchaseDetails = async () => {
  setLoading(true);
  try {
    const details = await Promise.all(
      selected.map(async (customer) => {
        const res = await sales_management_service.getEachOrder(
          Number(customer.id)
        );
        const data = res.data.info[0];

        const detail: PurchaseDetail = {
          customer: data.user_name,
          salesId: data.reserv_id,
          movieName:
            language === "en" ? data.eventName_en : data.eventName_am,
          movieSubtitle:
            language === "en" ? data.description_en : data.description_am,
          movieImage: data.movieImage,
          tickets: data.tickets.split(","),
          ticketCount: Number(data.ticketCount),
          time: data.dateTime.split(" ")[1],
          date: data.dateTime.split(" ")[0],
          qr_url: data.qr_url,
        };
        return detail;
      })
    );
    setPurchaseDetails(details);
  } catch (error) {
    console.error(error);
    // Handle error appropriately
  } finally {
    setLoading(false);
  }
};

    if (selected.length > 0) {
      fetchPurchaseDetails();
    }
  }, [selected, language]);

  // Function to handle print
  const handlePrint = (detail: PurchaseDetail) => {
    const printContent = document.getElementById(
      `print-section-${detail.salesId}`
    );
    if (printContent) {
      const WindowPrt = window.open("", "", "width=800,height=600");
      if (WindowPrt) {
        WindowPrt.document.write(`
          <html>
            <head>
              <title>Print Purchase Details</title>
              <style>
                body { font-family: Arial, sans-serif; padding: 20px; }
                .print-container { border: 1px solid #ccc; padding: 20px; border-radius: 8px; }
                .header { display: flex; justify-content: space-between; align-items: center; }
                .customer-info { display: flex; align-items: center; margin-bottom: 20px; }
                .customer-info .icon { font-size: 40px; margin-right: 20px; }
                .qr-code { margin-bottom: 20px; }
                .movie-info { display: flex; align-items: flex-start; }
                .movie-image { width: 150px; height: 225px; background-color: #ddd; border-radius: 8px; overflow: hidden; margin-right: 20px; }
                .movie-image img { width: 100%; height: 100%; object-fit: cover; }
                .tickets { display: flex; align-items: center; margin-bottom: 10px; }
              </style>
            </head>
            <body>
              ${printContent.innerHTML}
            </body>
          </html>
        `);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
        WindowPrt.close();
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      {purchaseDetails.map((detail) => (
        <Box key={detail.salesId} mb={4}>
          <Paper elevation={3} sx={{ padding: 3 }}>
            {/* Header Section */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={2}
            >
              <Box>
                <Typography variant="h5" gutterBottom>
                  Purchase Details
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  Customer ID: {detail.salesId}
                </Typography>
              </Box>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4D67EB",
                  color: "white",
                  padding: "8px 28px",
                  borderRadius: "6px",
                  fontSize: "1rem",
                  textTransform: "none",
                }}
                onClick={() => handlePrint(detail)}
              >
                Print
              </Button>
            </Box>

            {/* Customer Info Section */}
            <Box display="flex" alignItems="center" mb={3}>
              <MovieIcon sx={{ fontSize: 40, mr: 2 }} />
              <Box>
                <Typography variant="h6">{detail.customer}</Typography>
                {/* You can include email or other info if needed */}
              </Box>
            </Box>

            {/* QR Code and Sales Info Section */}
            <Box
              display="flex"
              flexDirection={isSmallScreen ? "column" : "row"}
              alignItems={"flex-start"}
              mb={3}
            >
              <Box sx={{ width: 150, height: 150, mb: isSmallScreen ? 2 : 0 }}>
                {detail.qr_url && <QRCode value={detail.qr_url} size={150} />}
              </Box>
              <Box
                sx={{
                  ml: isSmallScreen ? 0 : 4,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  Printing is unnecessary, enjoy your event!
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ mr: isSmallScreen ? 0 : 4 }}
                >
                  SALES ID: {detail.salesId}
                </Typography>
              </Box>
            </Box>

            <Divider sx={{ borderStyle: "dotted", my: 3 }} />
            <Box
              display="flex"
              flexDirection={"row"}
              alignItems={"flex-start"}
              mb={3}
              id={`print-section-${detail.salesId}`}
            >
              {/* Movie/Event Image */}
              <Box
                sx={{
                  width: 150,
                  height: 225,
                  borderRadius: "8px",
                  overflow: "hidden",
                  backgroundColor: "#ddd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 2,
                }}
              >
                {detail.movieImage ? (
                  <img
                    src={detail.movieImage}
                    alt={detail.movieName}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <MovieIcon sx={{ fontSize: 60, color: "#888" }} />
                )}
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Box>
                  <Typography variant="h6" fontWeight="bold">
                    {detail.movieName}
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary">
                    {detail.movieSubtitle}
                  </Typography>
                </Box>
                <Box mb={2}>
                  <Box display="flex" alignItems="center">
                    {detail.tickets.map((ticket, index) => (
                      <TicketBox key={index}>
                        <Typography variant="body2">{ticket}</Typography>
                      </TicketBox>
                    ))}
                    <Typography variant="body2">
                      ({detail.ticketCount} tickets)
                    </Typography>
                  </Box>
                </Box>

                {/* Time and Date Section */}
                <Box>
                  <Typography variant="body1">
                    {detail.time} | {detail.date}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
    </Box>
  );
};

// Main CustomerListTable Component
const CustomerListTable: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { ordersList } = useSelector(
    (state: RootState) => state?.salesManagementReducer
  );

  // Map ordersList to generate customers array
  const customers: Customer[] = ordersList.map((order) => ({
    id: order.id,
    purchaseDate: order.po_date,
    customerName: order.user_name,
    email: order.user_email,
    purchaseReceipt: order.reserv_id,
  }));

  // State for selected customers (array of customer IDs)
  const [selected, setSelected] = useState<string[]>([]);

  // State for pagination
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  // State to handle view (table or selected customers)
  const [isExporting, setIsExporting] = useState<boolean>(false);

  // Handle selecting all customers
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = customers.map((customer) => customer.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  // Handle selecting a single customer
  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  // Handle page change
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handle Export button click
  const handleExport = () => {
    setIsExporting(true);
  };

  // Handle Back button click in export screen
  const handleBack = () => {
    setIsExporting(false);
  };

  // Determine if all rows are selected
  const isAllSelected =
    selected.length === customers.length && customers.length > 0;

  // Get selected customer objects
  const selectedCustomers = customers.filter((customer) =>
    selected.includes(customer.id)
  );

  // If exporting, show the selected customers component
  if (isExporting) {
    return (
      <Box
        display="flex"
        minHeight="90vh"
        width="100vw"
        bgcolor="#f0f2f5"
        padding={2}
        justifyContent="center"
        alignItems="flex-start"
      >
        <Container>
          <Box display="flex" alignItems="center" mb={2}>
            <BackButton onClick={handleBack} style={{ marginBottom: 1 }}>
              <ArrowBackIcon />
            </BackButton>
            <Typography
              variant={isSmallScreen ? "h6" : "h5"}
              gutterBottom
              sx={{ ml: isSmallScreen ? 1 : 2 }}
            >
              Back To Customers List
            </Typography>
          </Box>
          <SelectedCustomers selected={selectedCustomers} onBack={handleBack} />
        </Container>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      minHeight="90vh"
      width="100vw"
      bgcolor="#f0f2f5"
      padding={2}
      justifyContent="center"
      alignItems="flex-start"
    >
      <Container>
        {/* Title Section */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexDirection={{ xs: "column", sm: "row" }}
          gap={2}
        >
          <Box>
            <Typography variant="h5" gutterBottom>
              Customer List
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              You have {customers.length.toLocaleString()} customers
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleExport}
            disabled={selected.length === 0}
            style={{
              backgroundColor: selected.length === 0 ? "#a0a0a0" : "#4D67EB",
              color: "white",
              padding: "8px 28px",
              borderRadius: "6px",
              fontSize: "1rem",
              textTransform: "none",
            }}
          >
            Export Customer Info
          </Button>
        </Box>

        {/* Table Section */}
        <Box mt={4}>
          <Paper elevation={0}>
            {/* Removed box shadow by setting elevation to 0 */}
            <TableContainer
              sx={{
                overflowX: "auto", // Enable horizontal scrolling on small screens
              }}
            >
              <Table
                sx={{
                  minWidth: 650, // Ensure table has a minimum width
                }}
                size={isSmallScreen ? "small" : "medium"}
              >
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" sx={{ borderBottom: "none" }}>
                      <Checkbox
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < customers.length
                        }
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                        inputProps={{
                          "aria-label": "select all customers",
                        }}
                      />
                    </TableCell>
                    {!isSmallScreen && (
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography fontWeight="bold">CUST</Typography>
                      </TableCell>
                    )}
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography>PURCHASE DATE</Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography fontWeight="bold">CUSTOMER NAME</Typography>
                    </TableCell>
                    {!isSmallScreen && (
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography>EMAIL</Typography>
                      </TableCell>
                    )}
                    <TableCell sx={{ borderBottom: "none" }}>
                      <Typography>PURCHASE RECEIPT</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((customer) => {
                      const isItemSelected = selected.includes(customer.id);
                      return (
                        <TableRow
                          key={customer.id}
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          selected={isItemSelected}
                          onClick={() => handleSelect(customer.id)}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={() => handleSelect(customer.id)}
                              onClick={(e) => e.stopPropagation()} // Prevent row click from toggling selection twice
                              inputProps={{
                                "aria-labelledby": `customer-checkbox-${customer.id}`,
                              }}
                            />
                          </TableCell>
                          {!isSmallScreen && (
                            <TableCell sx={{ borderBottom: "none" }}>
                              {customer.id}
                            </TableCell>
                          )}
                          <TableCell sx={{ borderBottom: "none" }}>
                            {customer.purchaseDate}
                          </TableCell>
                          <TableCell sx={{ borderBottom: "none" }}>
                            {customer.customerName}
                          </TableCell>
                          {!isSmallScreen && (
                            <TableCell sx={{ borderBottom: "none" }}>
                              {customer.email}
                            </TableCell>
                          )}
                          <TableCell sx={{ borderBottom: "none" }}>
                            {customer.purchaseReceipt}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* Pagination */}
            <TablePagination
              component="div"
              count={customers.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50]}
              sx={{
                ".MuiTablePagination-toolbar": {
                  flexDirection: isSmallScreen ? "column" : "row",
                  alignItems: isSmallScreen ? "flex-start" : "center",
                  gap: isSmallScreen ? theme.spacing(1) : 0,
                },
                ".MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows":
                  {
                    marginRight: isSmallScreen ? 0 : theme.spacing(2),
                  },
              }}
            />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default CustomerListTable;
