import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EnhancedTable from "../../Components/EventsComponents/EventsTableComponent";
import EventsCalendarComponent from "../../Components/EventsComponents/EventsCalendarComponent";
import { useNavigate } from "react-router-dom";
import events_service from "../../services/events_service";
import { useDispatch } from "react-redux";
import { setEventsList } from "../../reducers/event_reducer";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    paddingBottom: theme.spacing(2),
  },
  container: {
    width: "98%",
    backgroundColor: "#fff",
    padding: "20px",
  },
  titlesContainer: {
    width: "98%",
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#4D67EB",
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3755a3",
    },
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  loaderBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const EventsPage: React.FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true); // Loader state
  const { t } = useTranslation();

  useEffect(() => {
    events_service
      .getEventsList()
      .then((res) => {
        dispatch(setEventsList(res.data.status.events));
        setLoading(false); // Deactivate loader
      })
      .catch(() => {
        setLoading(false); // Deactivate loader in case of error
        MySwal.fire({
          title: "Error",
          text: "Failed to fetch events.",
          icon: "error",
          confirmButtonText: "OK",
          backdrop: `rgba(0,0,0,0.5)`
        });
      });
  }, [dispatch]);

  return (
    <Box className={classes.root}>
      <Backdrop className={classes.loaderBackdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.titlesContainer}>
        <Typography variant="h6">{t("Events & Schedules")}</Typography>
        <Typography color="textSecondary">Past & upcoming events</Typography>
      </Box>
      <Box className={classes.container}>
        <Box className={classes.buttonContainer}>
          <AddEventButton />
        </Box>
        <Box>
          <EventsCalendarComponent />
        </Box>
        <Box>
          <EnhancedTable />
        </Box>
      </Box>
    </Box>
  );
};

const AddEventButton: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Button
      variant="contained"
      className={classes.addButton}
      size="small"
      onClick={() => {
        navigate("/events/create");
      }}
    >
      <AddCircleOutlineIcon className={classes.addIcon} />
      Add New Event
    </Button>
  );
};

export default EventsPage;