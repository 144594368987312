import { apiClient } from "./api";

export default {
  getSeatingPlansList() {
    return apiClient.post(`/seating/list`, { action: "list" });
  },
  createSeatingPlan(payload: any) {
    return apiClient.post(`/seatingplan/create`, payload);
  },
  updateSeatingPlan(payload: any) {
    return apiClient.post(`/seatingplan/update`, payload);
  },
  deleteSeatingPlan(payload: any) {
    return apiClient.post(`/seating/delete`, payload);
  },
};
