import React, { useEffect } from "react";
import OrderListTable from "../../Components/OrderListComponents/OrderListComponents";
import sales_management_service from "../../services/sales_management_service";
import { useDispatch } from "react-redux";
import { setOrdersList } from "../../reducers/sales_management_reducer";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const OrderListPage: React.FC = () => {
  const dispatch = useDispatch();
  const { ordersList } = useSelector(
    (state: RootState) => state?.salesManagementReducer
  );
  useEffect(() => {
    if (ordersList.length === 0)
      sales_management_service.getOrdersListList().then((res) => {
        dispatch(setOrdersList(res.data.orders));
      });
  }, []);
  return <OrderListTable />;
};

export default OrderListPage;
