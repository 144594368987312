import React, { useState } from "react";
import Modal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard"; // Import CopyToClipboard
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import events_service from "../../services/events_service";
import { setEventsList } from "../../reducers/event_reducer";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import ModalImage from "react-modal-image";
import "./style.css";
import { useTranslation } from "react-i18next";
import ContentCopyIcon from "@mui/icons-material/ContentCopy"; // Import copy icon from MUI

interface Data {
  id: number;
  eventName: string;
  description: string;
  imageurl: string;
  dateTime: string;
  eventCategory: string;
  eventId: string;
  hallId: string;
  status: string;
}

function createData(
  id: number,
  eventName: string,
  description: string,
  imageurl: string,
  dateTime: string,
  eventCategory: string,
  eventId: string,
  hallId: string,
  status: string
): Data {
  return {
    id,
    eventName,
    description,
    imageurl,
    dateTime,
    eventCategory,
    eventId,
    hallId,
    status,
  };
}

const getFieldValue = (event: any, field: string, lang: string) => {
  const langField = `${field}_${lang}`;
  return event[langField] || event[field];
};

const parseEventList = (eventList: any, lang: string) => {
  const eventsArray = Object.values(eventList).filter(
    (item: any) => typeof item === "object" && item.event_id
  );
  return eventsArray
    .reverse()
    .map((event: any, index: number) =>
      createData(
        index + 1,
        getFieldValue(event, "eventName", lang),
        getFieldValue(event, "description", lang),
        event.imageurl,
        event.dateTime,
        event.eventCategory,
        event.event_id,
        event.hallID,
        event.status
      )
    );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "eventName",
    numeric: false,
    disablePadding: true,
    label: "Event Name",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "imageurl",
    numeric: false,
    disablePadding: false,
    label: "Event Image",
  },
  {
    id: "dateTime",
    numeric: false,
    disablePadding: false,
    label: "Date & Time",
  },
  {
    id: "eventCategory",
    numeric: false,
    disablePadding: false,
    label: "Event Category",
  },
  {
    id: "eventId",
    numeric: false,
    disablePadding: false,
    label: "Event ID",
  },
  {
    id: "hallId",
    numeric: false,
    disablePadding: false,
    label: "Hall ID",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "Actions",
  },
];

interface EnhancedTableProps {
  order: Order;
  orderBy: string;
  rowCount: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

// Initialize the modal
Modal.setAppElement("#root");

export default function EnhancedTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("eventName");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { eventsReducer } = useSelector((state: RootState) => state);
  const { eventsList, selectedDay } = eventsReducer;
  const { i18n } = useTranslation();
  const { language } = i18n;
  const rows = parseEventList(eventsList, language);

  const filteredRows = selectedDay
    ? rows.filter(
        (row) =>
          format(new Date(row.dateTime), "dd/MM/yyyy") ===
          format(new Date(selectedDay), "dd/MM/yyyy")
      )
    : rows;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows?.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, filteredRows]
  );

  const handleEditClick = (eventId: string) => {
    navigate(`/events/create/${eventId}`);
  };

  const handleDeleteClick = (eventId: string) => {
    events_service.deleteEvent({ event_id: eventId }).then(() => {
      toast.success("Event deleted successfully");
      events_service.getEventsList().then((res) => {
        dispatch(setEventsList(res.data.status.events));
      });
    });
  };

  const truncateText = (text: string, length: number) => {
    return text?.length > length ? text.substring(0, length) + "..." : text;
  };

  // Handle opening the modal
  const openModal = (content: string) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  // Handle closing the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Box sx={{ width: "100%", height: "400px" }}>
      <ToastContainer />
      {/* Enhanced Modal Styling */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Event Info Modal"
        style={{
          content: {
            top: "30%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            border: "none",
            backgroundColor: "#f7f7f7", // Changed background color to near white
          },
          overlay: {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 999000,
          },
        }}
      >
        {/* Added Title for Modal */}
        <h3 style={{ color: "#4D67EB", marginBottom: "10px" }}>
          Generated URL
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ marginBottom: "20px", wordWrap: "break-word", flex: 1 }}>
            <a href={modalContent} target="_blank" rel="noopener noreferrer">
              {modalContent}
            </a>
          </p>
          <div
            style={{
              cursor: "pointer",
              color: "#4D67EB",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            <CopyToClipboard text={modalContent}>
              <ContentCopyIcon
                onClick={() => toast.success("Copied to clipboard!")}
                style={{
                  color: "#4D67EB",
                  cursor: "pointer",
                  fontSize: "24px",
                }}
              />
            </CopyToClipboard>
          </div>
        </div>
      </Modal>

      <TableContainer sx={{ maxHeight: "350px" }}>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="small"
          stickyHeader
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={filteredRows?.length}
          />
          <TableBody>
            {visibleRows.map((row, index) => {
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                  sx={{ cursor: "pointer", height: "40px" }}
                >
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.eventName, 10)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.description, 10)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <ModalImage
                      small={row.imageurl}
                      large={row.imageurl}
                      alt={row.eventName}
                      hideDownload={false}
                      hideZoom={false}
                      className="thumbnail"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.dateTime, 10)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.eventCategory, 10)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.eventId, 10)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.hallId, 10)}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ whiteSpace: "pre-wrap" }}>
                      {truncateText(row.status, 10)}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ cursor: "pointer", width: "120px" }}
                  >
                    <svg
                      onClick={() => {
                        if (language === "en") {
                          openModal(
                            `https://babajanyanhall.org/en/seat/?event=${
                              row.eventId
                            }&event_lang=${language}&eventname= ${row.eventName.replaceAll(
                              " ",
                              "%20"
                            )}`
                          );
                        } else
                          openModal(
                            `https://babajanyanhall.org/seat/?event=${
                              row.eventId
                            }&event_lang=${language}&eventname= ${row.eventName.replaceAll(
                              " ",
                              "%20"
                            )}`
                          );
                      }}
                      stroke="currentColor"
                      fill="#4D67EB"
                      strokeWidth="0"
                      viewBox="0 0 512 512"
                      width="20px"
                      height="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M280 341.1l-1.2.1c-3.6.4-7 2-9.6 4.5l-64.6 64.6c-13.7 13.7-32 21.2-51.5 21.2s-37.8-7.5-51.5-21.2c-13.7-13.7-21.2-32-21.2-51.5s7.5-37.8 21.2-51.5l68.6-68.6c3.5-3.5 7.3-6.6 11.4-9.3 4.6-3 9.6-5.6 14.8-7.5 4.8-1.8 9.9-3 15-3.7 3.4-.5 6.9-.7 10.2-.7 1.4 0 2.8.1 4.6.2 17.7 1.1 34.4 8.6 46.8 21 7.7 7.7 13.6 17.1 17.1 27.3 2.8 8 11.2 12.5 19.3 10.1.1 0 .2-.1.3-.1.1 0 .2 0 .2-.1 8.1-2.5 12.8-11 10.5-19.1-4.4-15.6-12.2-28.7-24.6-41-15.6-15.6-35.9-25.8-57.6-29.3-1.9-.3-3.8-.6-5.7-.8-3.7-.4-7.4-.6-11.1-.6-2.6 0-5.2.1-7.7.3-5.4.4-10.8 1.2-16.2 2.5-1.1.2-2.1.5-3.2.8-6.7 1.8-13.3 4.2-19.5 7.3-10.3 5.1-19.6 11.7-27.7 19.9l-68.6 68.6C58.9 304.4 48 330.8 48 359c0 28.2 10.9 54.6 30.7 74.4C98.5 453.1 124.9 464 153 464c28.2 0 54.6-10.9 74.4-30.7l65.3-65.3c10.4-10.5 2-28.3-12.7-26.9z"></path>
                      <path d="M433.3 78.7C413.5 58.9 387.1 48 359 48s-54.6 10.9-74.4 30.7l-63.7 63.7c-9.7 9.7-3.6 26.3 10.1 27.4 4.7.4 9.3-1.3 12.7-4.6l63.8-63.6c13.7-13.7 32-21.2 51.5-21.2s37.8 7.5 51.5 21.2c13.7 13.7 21.2 32 21.2 51.5s-7.5 37.8-21.2 51.5l-68.6 68.6c-3.5 3.5-7.3 6.6-11.4 9.3-4.6 3-9.6 5.6-14.8 7.5-4.8 1.8-9.9 3-15 3.7-3.4.5-6.9.7-10.2.7-1.4 0-2.9-.1-4.6-.2-17.7-1.1-34.4-8.6-46.8-21-7.3-7.3-12.8-16-16.4-25.5-2.9-7.7-11.1-11.9-19.1-9.8-8.9 2.3-14.1 11.7-11.3 20.5 4.5 14 12.1 25.9 23.7 37.5l.2.2c16.9 16.9 39.4 27.6 63.3 30.1 3.7.4 7.4.6 11.1.6 2.6 0 5.2-.1 7.8-.3 6.5-.5 13-1.6 19.3-3.2 6.7-1.8 13.3-4.2 19.5-7.3 10.3-5.1 19.6-11.7 27.7-19.9l68.6-68.6c19.8-19.8 30.7-46.2 30.7-74.4s-11.1-54.6-30.9-74.4z"></path>
                    </svg>
                    {/* Edit SVG */}
                    <svg
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleEditClick(row.eventId)}
                    >
                      <path
                        d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.04 3.01976L8.16 10.8998C7.86 11.1998 7.56 11.7898 7.5 12.2198L7.07 15.2298C6.91 16.3198 7.68 17.0798 8.77 16.9298L11.78 16.4998C12.2 16.4398 12.79 16.1398 13.1 15.8398L20.98 7.95976C22.34 6.59976 22.98 5.01976 20.98 3.01976C18.98 1.01976 17.4 1.65976 16.04 3.01976Z"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {/* Delete SVG */}
                    <svg
                      width="20px"
                      height="20px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={() => handleDeleteClick(row.eventId)}
                    >
                      <path
                        d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M18.8499 9.14014L18.1999 19.2101C18.0899 20.7801 17.9999 22.0001 15.2099 22.0001H8.7899C5.9999 22.0001 5.9099 20.7801 5.7999 19.2101L5.1499 9.14014"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M10.3301 16.5H13.6601"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M9.5 12.5H14.5"
                        stroke="#4D67EB"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow>
                <TableCell colSpan={9} sx={{ height: "40px" }} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredRows?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
