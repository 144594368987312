import { apiClient } from "./api";

export default {
  getHallsList() {
    return apiClient.post(`/hall/list`, { action: "list" });
  },
  createHall(payload: any) {
    return apiClient.post(`/hall/create`, payload);
  },
  updateHall(payload: any) {
    return apiClient.post(`/hall/update`, payload);
  },
  deleteHall(payload: any) {
    return apiClient.post(`/halls/delete`, payload);
  },
};
