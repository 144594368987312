import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import AuthPageWrapperComponent from "../../Components/AuthenticationComponents/AuthPageWrapperComponent";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography } from "@material-ui/core";
import { AppLogo } from "../../Helpers/constants";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    backgroundColor: theme.palette.primary.main,
  },
  paper: {
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#fff",
  },
  viewPart: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center vertically
    height: "100%", // Fill the height of the parent container
    width: "100%", // Fill the width of the parent container
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#4D67EB",
  },
  viewRightPart: {
    position: "relative",
  },
  image: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    width: "100%",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
  },
  errorMessage: {
    color: "red",
  },
  backSymbol: {
    width: "100%",
    cursor: "pointer",
  },
  titleDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    fontSize: "30px",
    letterSpacing: "2px",
    marginTop: "10px",
  },
  moviesImg: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "60%",
    height: "50%",
    position: "absolute",
    top: "10%",
    left: "28%",
  },
  linkLabels: {
    color: "#4D67EB",
    cursor: "pointer",
  },
}));

const RegisterPage: React.FC = () => {
  const classes = useStyles();
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const handleSubmit = (values: any) => {
    // Handle form submission here
  };
  return (
    <AuthPageWrapperComponent withBack={true}>
      <div className={classes.formContainer}>
        <div className={classes.titleDiv}>Forgot Password</div>
        <Formik
          initialValues={{ email: "", password: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, errors, touched }) => (
            <Form className={classes.form}>
              <Field
                as={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email_forgot_password"
                label="Email Address"
                name="email_forgot_password"
                autoComplete="email_forgot_password"
                error={errors.email && touched.email ? true : false}
                autoFocus
              />
              <ErrorMessage
                name="email"
                component="div"
                className={classes.errorMessage}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                disabled={isSubmitting}
              >
                Send
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </AuthPageWrapperComponent>
  );
};

export default RegisterPage;
