import { Hall, HallsState } from "../types";
import { hallsActionTypes } from "./action_types";

const initialState: HallsState = {
  hallsList: [],
  currentHall: null
};

const { SET_HALLS_LIST, SET_CURRENT_HALL } = hallsActionTypes;

const hallsReducer = (
  state: HallsState = initialState,
  action: HallsAction
): HallsState => {
  switch (action.type) {
    case SET_HALLS_LIST:
      return {
        ...state,
        hallsList: action.payload.data,
      };
    case SET_CURRENT_HALL:
      return {
        ...state,
        currentHall: action.payload.data,
      };
    default:
      return state;
  }
};

export const setHallsList = (data: Hall[]) => ({
  type: SET_HALLS_LIST,
  payload: {
    data,
  },
});

export const setCurrentHall = (data: Hall) => ({
  type: SET_CURRENT_HALL,
  payload: {
    data,
  },
});

export type HallsAction = ReturnType<typeof setHallsList> ;

export default hallsReducer;
