import { apiClient } from "./api";

export default {
  getOrdersListList() {
    return apiClient.post(`/order/list`, { action: "action=orderlist" });
  },
  getEachOrder(id: Number) {
    return apiClient.post(`/order/info`, { id: id });
  },
};
