import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import halls_service from "../../services/halls_service";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentHall, setHallsList } from "../../reducers/hall_reducer";
import { RootState } from "../../store/store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "88vh",
    paddingBottom: theme.spacing(2),
    overflowY: "auto",
  },
  container: {
    width: "98%",
    backgroundColor: "#fff",
    padding: "20px",
    marginTop: theme.spacing(6),
  },
  titlesContainer: {
    width: "98%",
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  addButton: {
    backgroundColor: "#4D67EB",
    fontSize: "12px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#3755a3",
    },
  },
  addIcon: {
    marginRight: theme.spacing(1),
  },
  containerTable: {
    width: "100%",
    marginTop: theme.spacing(2),
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(2),
    borderBottom: "1px solid #ddd",
  },
  rowLeft: {
    fontWeight: 700,
    fontSize: "16px",
    color: "#191919",
  },
  rowId: {
    fontSize: "14px",
    color: theme.palette.text.secondary,
  },
  rowRight: {
    display: "flex",
    alignItems: "center",
  },
  iconButton: {
    marginLeft: theme.spacing(1),
    cursor: "pointer",
    color: "#4D67EB",
  },
  customSwalContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    zIndex: 9999,
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  customSwalPopup: {
    width: "50%",
    height: "50%",
  },
  loaderBackdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

interface Hall {
  hall_id: string;
  hasseating: string;
  [key: string]: any;
}

const HallsPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hallsReducer } = useSelector((state: RootState) => state);
  const { hallsList } = hallsReducer;
  const [loading, setLoading] = useState(true); // Loader state
  const { i18n } = useTranslation();
  const { language } = i18n;

  useEffect(() => {
    halls_service
      .getHallsList()
      .then((res) => {
        const hallsList = res.data.status.halls;
        if (hallsList.length) dispatch(setHallsList(hallsList));
        setLoading(false); // Deactivate loader
      })
      .catch(() => {
        setLoading(false); // Deactivate loader in case of error
      });
  }, [dispatch]);

  const handleDelete = (hallId: string, hasSeating: string) => {
    if (hasSeating === "1") {
      MySwal.fire({
        title: "Unable to Delete",
        text: "The hall is being used in an event or has a seatmap, so it cannot be deleted.",
        icon: "warning",
        confirmButtonText: "OK",
        customClass: {
          popup: classes.customSwalPopup,
          container: classes.customSwalContainer,
        },
      });
      return;
    }

    halls_service.deleteHall({ hall_id: hallId }).then((res) => {
      if (res.status === 200) {
        const updatedHallsList = hallsList.filter(
          (hall: Hall) => hall.hall_id !== hallId
        );
        dispatch(setHallsList(updatedHallsList));
        toast.success("Hall has been deleted.");
      } else {
        toast.error("Failed to delete hall.");
      }
    });
  };

  return (
    <Box className={classes.root}>
      <ToastContainer />
      <Backdrop className={classes.loaderBackdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.container}>
        <Box className={classes.buttonContainer}>
          <Typography variant="body1" style={{ fontWeight: 800 }}>
            Halls
          </Typography>
          <AddEventButton />
        </Box>
        <Box className={classes.containerTable}>
          {hallsList.map((row: Hall) => (
            <Box key={row.hall_id} className={classes.row}>
              <Box>
                <Typography className={classes.rowLeft}>
                  {row[`hallName_${language}`] as string}
                </Typography>
                <Typography className={classes.rowId}>
                  ID #{row.hall_id}
                </Typography>
              </Box>
              <Box className={classes.rowRight}>
                <svg
                  style={{
                    marginRight: "10px",
                    cursor: "pointer",
                  }}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => navigate(`/halls/edit/${row.hall_id}`)}
                >
                  <path
                    d="M11 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15C20 22 22 20 22 15V13"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.04 3.01976L8.16 10.8998C7.86 11.1998 7.56 11.7898 7.5 12.2198L7.07 15.2298C6.91 16.3198 7.68 17.0798 8.77 16.9298L11.78 16.4998C12.2 16.4398 12.79 16.1398 13.1 15.8398L20.98 7.95976C22.34 6.59976 22.98 5.01976 20.98 3.01976C18.98 1.01976 17.4 1.65976 16.04 3.01976Z"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.91 4.1499C15.58 6.5399 17.45 8.4099 19.85 9.0899"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleDelete(row.hall_id, row.hasseating)}
                >
                  <path
                    d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M18.8499 9.14014L18.1999 19.2101C18.0899 20.7801 17.9999 22.0001 15.2099 22.0001H8.7899C5.9999 22.0001 5.9099 20.7801 5.7999 19.2101L5.1499 9.14014"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M10.3301 16.5H13.6601"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M9.5 12.5H14.5"
                    stroke="#4D67EB"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

const AddEventButton: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <Button
      variant="contained"
      className={classes.addButton}
      size="small"
      onClick={() => {
        navigate("/halls/create");
        dispatch(
          setCurrentHall({
            hall_id: "",
            hallName_am: "",
            hallName_en: "",
            hasSeatmap: "",
            country: "",
            city: "",
            street: "",
            hasseating: "",
          })
        );
      }}
    >
      <AddCircleOutlineIcon className={classes.addIcon} />
      Add New Hall
    </Button>
  );
};

export default HallsPage;