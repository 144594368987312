// Sidebar.tsx

import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { NavLink, useNavigate } from "react-router-dom";
import { AppLogo } from "../Helpers/constants";
import s from "./layouts_style.module.css";
import menu_items_arr from "../Helpers/menu_items";
import { useTranslation } from "react-i18next";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const drawerWidth = 260;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#FFFFFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    nested: {
      paddingLeft: theme.spacing(6), // Increased padding for subitems
      // Alternatively, use marginLeft if preferred
      // marginLeft: theme.spacing(1),
    },
    listItem: {
      margin: "0 auto",
      color: "#4D67EB"
    },
    helpIconContainer: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(2),
    },
    helpIcon: {
      width: "100%",
      height: "auto",
    },
  })
);

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>({});

  const handleClick = (itemName: string, path: string) => {
    setOpenItems((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));
    navigate(path);
  };

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <AppLogo />
          </div>
          <List
            style={{
              padding: "10px",
            }}
          >
            {menu_items_arr.map((item, index) => {
              const Icon = item.icon;
              const isExpandable = !!item.subItems;
              const isOpen = openItems[item.name];
              if (isExpandable) {
                return (
                  <React.Fragment key={index + item.name}>
                    <ListItem
                      button
                      onClick={() => handleClick(item.name, item.path)} // Pass path
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={t(`menu.${item.name}`, item.name)}
                      />
                      {isOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.subItems?.map((subItem, subIndex) => {
                          return (
                            <NavLink
                              key={subIndex + subItem.path}
                              to={subItem.path}
                              className={({ isActive }) =>
                                isActive ? s.activeItem : s.navLinkItem
                              }
                              style={{ textDecoration: "none", color: "inherit" }} // Ensure no underline and inherit color
                            >
                              <div className={s.navLinkItemDiv}>
                                <ListItem
                                  button
                                  className={`${classes.listItem} ${classes.nested}`}
                                >
                                  <ListItemText
                                    primary={t(
                                      `menu.${subItem.name}`,
                                      subItem.name
                                    )}
                                  />
                                </ListItem>
                              </div>
                            </NavLink>
                          );
                        })}
                      </List>
                    </Collapse>
                  </React.Fragment>
                );
              } else {
                return (
                  <NavLink
                    key={index + item.path}
                    to={item.path}
                    className={({ isActive }) =>
                      isActive ? s.activeItem : s.navLinkItem
                    }
                    style={{ textDecoration: "none", color: "inherit" }} // Ensure no underline and inherit color
                  >
                    <div className={s.navLinkItemDiv}>
                      <ListItem button className={classes.listItem}>
                        <ListItemIcon>
                          <Icon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t(`menu.${item.name}`, item.name)}
                        />
                      </ListItem>
                    </div>
                  </NavLink>
                );
              }
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default Sidebar;