import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import ReactCountryFlag from 'react-country-flag';

const languageOptions = [
  { value: 'en', label: 'English', flag: 'US' },
  { value: 'am', label: 'Armenia', flag: 'AM' },
];

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    maxWidth: 80,
    border: 'none',
    boxShadow: 'none',
    marginRight: 30,
    borderRadius: 0,
  }),
  option: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: 0,
  }),
};

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (selectedOption: any) => {
    i18n.changeLanguage(selectedOption.value);
  };

  const selectedOption = languageOptions.find(
    (option) => option.value === i18n.language
  );

  return (
    <Select
      value={selectedOption}
      onChange={changeLanguage}
      options={languageOptions}
      styles={customStyles}
      formatOptionLabel={(option: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ReactCountryFlag countryCode={option.flag} svg style={{ marginRight: 10 }} />
          {option.label}
        </div>
      )}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default LanguageSwitcher;