import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Checkbox,
  Tooltip, // Import Tooltip
} from "@mui/material";
import { styled } from "@mui/system";
import { Box } from "@material-ui/core";
import { data_example } from "../../Pages/SeatingPlansPage/data_example";

const CustomCheckbox = styled(Checkbox)({
  "& .MuiSvgIcon-root": {
    fill: "transparent",
    stroke: "white",
  },
  "&.Mui-checked": {
    "& .MuiSvgIcon-root": {
      fill: "white",
      stroke: "white",
    },
  },
});

const StyledTableCell = styled(TableCell)({
  padding: "0 5px",
  border: "none",
});

interface SeatingPlanTableProps {
  seatColor: string;
  price: number;
  selectedCells: any;
  setSelectedCells: React.Dispatch<React.SetStateAction<any>>;
  appliedCells: any;
}

function addMissingNumbers(arr: string[]): string[] {
  const numArr: number[] = arr.map(Number);
  const min: number = Math.min(...numArr);
  const max: number = Math.max(...numArr);
  const currentNumbers: Set<number> = new Set(numArr);
  const completeRange: number[] = Array.from(
    { length: max - min + 1 },
    (_, i) => i + min
  );
  const missingNumbers: number[] = completeRange.filter(
    (num) => !currentNumbers.has(num)
  );
  const missingNumbersAsString: string[] = missingNumbers.map(String);
  return [...arr, ...missingNumbersAsString].sort((a, b) => Number(a) - Number(b));
}

const SeatingPlanTableNew: React.FC<SeatingPlanTableProps> = ({
  seatColor,
  price,
  selectedCells,
  setSelectedCells,
  appliedCells,
}) => {
  const handleCellClick = (rowIndex: string, colIndex: string) => {
    const tempObj = { ...selectedCells };
    if (!tempObj[rowIndex]) {
      tempObj[rowIndex] = {};
    }
    if (!tempObj?.[rowIndex]?.[colIndex]) {
      tempObj[rowIndex][colIndex] = {
        price,
        status: "open",
        ordered: "no",
        category: seatColor,
      };
    } else {
      delete tempObj[rowIndex][colIndex];
      if (Object.keys(tempObj[rowIndex]).length === 0) {
        delete tempObj[rowIndex];
      }
    }

    setSelectedCells(tempObj);
  };

  const handleRowCheckboxChange = (rowIndex: string) => {
    setSelectedCells((prevSelectedCells: any) => {
      const newSelectedCells = { ...prevSelectedCells };
      const currentColumns = data_example[rowIndex as keyof typeof data_example];

      if (
        newSelectedCells[rowIndex] &&
        Object.keys(newSelectedCells[rowIndex]).length ===
          Object.keys(currentColumns).length
      ) {
        delete newSelectedCells[rowIndex];
      } else {
        newSelectedCells[rowIndex] = {};
        Object.keys(currentColumns).forEach((colIndex) => {
          newSelectedCells[rowIndex][colIndex] = {
            price,
            status: "open",
            ordered: "no",
            category: seatColor,
          };
        });
      }

      return newSelectedCells;
    });
  };

  const arrayOfLength18 = Array.from({ length: 18 }, () => 0);
  const rowsIndexArr = Object.keys(data_example);

  return (
    <div
      style={{
        padding: "10px",
        width: "80%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{
          width: "70%",
          maxWidth: "800px",
          backgroundColor: "#9F835A",
          paddingRight: "30px",
          borderRadius: "10px",
          padding: "10px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            marginLeft: "60px",
          }}
        >
          <svg
            width="450"
            height="62"
            viewBox="0 0 604 62"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 56C121.179 -6.02401 460 -15.1532 598 55.9999"
              stroke="white"
              strokeWidth="12"
              strokeLinecap="round"
            />
          </svg>
          <Box
            style={{
              fontSize: "20px",
              fontWeight: "700",
              color: "white",
              position: "absolute",
              top: "25px",
            }}
          >
            Stage
          </Box>
        </Box>
        <Table size="small">
          <TableBody>
            {rowsIndexArr?.map((row_index) => {
              const currentColumns =
                appliedCells[row_index as keyof typeof appliedCells];
              if (row_index === "8") {
                return (
                  <React.Fragment key={`row-${row_index}`}>
                    <TableRow key={`row-${row_index}-10`} style={{ height: "30px" }}>
                      <StyledTableCell padding="checkbox">
                        <CustomCheckbox
                          size="small"
                          checked={
                            selectedCells[row_index]
                              ? Object.keys(selectedCells[row_index]).length ===
                                Object.keys(currentColumns).length
                              : false
                          }
                          onChange={() => handleRowCheckboxChange(row_index)}
                        />
                      </StyledTableCell>
                      {Object.keys(currentColumns)?.map((column_index) => {
                        if (column_index === "9")
                          return (
                            <React.Fragment
                              key={`row-${row_index}-col-${column_index}`}
                            >
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-1`}
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                }}
                              >
                                {column_index}
                              </TableCell>
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-2`}
                                onClick={() => {
                                  handleCellClick(row_index, column_index);
                                }}
                                style={{
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                }}
                              >
                                {/* Tooltip Wrapper */}
                                {(() => {
                                  const cellData =
                                    (selectedCells[row_index] &&
                                      selectedCells[row_index][column_index]) ||
                                    currentColumns[column_index];
                                  const cellPrice = cellData?.price;
                                  return (
                                    <Tooltip
                                      title={`Price: ${
                                        cellPrice !== null && cellPrice !== undefined
                                          ? cellPrice
                                          : "Not available"
                                      }`}
                                    >
                                      <div
                                        style={{
                                          backgroundColor:
                                            selectedCells[row_index] &&
                                            selectedCells[row_index][column_index]
                                              ? selectedCells[row_index][column_index].category
                                              : currentColumns[
                                                  column_index as keyof typeof currentColumns
                                                ].category || "white",
                                          textAlign: "center",
                                          fontSize: "8px",
                                          margin: "auto",
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "18px",
                                          height: "18px",
                                          borderTopLeftRadius: "5px",
                                          borderTopRightRadius: "5px",
                                        }}
                                      ></div>
                                    </Tooltip>
                                  );
                                })()}
                              </TableCell>
                            </React.Fragment>
                          );
                        else {
                          return (
                            <TableCell
                              key={`row-${row_index}-col-${column_index}-3`}
                              onClick={() => {
                                handleCellClick(row_index, column_index);
                              }}
                              style={{
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                            >
                              {/* Tooltip Wrapper */}
                              {(() => {
                                const cellData =
                                  (selectedCells[row_index] &&
                                    selectedCells[row_index][column_index]) ||
                                  currentColumns[column_index];
                                const cellPrice = cellData?.price;
                                return (
                                  <Tooltip
                                    title={`Price: ${
                                      cellPrice !== null && cellPrice !== undefined
                                        ? cellPrice
                                        : "Not available"
                                    }`}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          selectedCells[row_index] &&
                                          selectedCells[row_index][column_index]
                                            ? selectedCells[row_index][column_index].category
                                            : currentColumns[
                                                column_index as keyof typeof currentColumns
                                              ].category || "white",
                                        textAlign: "center",
                                        fontSize: "8px",
                                        margin: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "18px",
                                        height: "18px",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                      }}
                                    ></div>
                                  </Tooltip>
                                );
                              })()}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                    <TableRow key={`row-${row_index}-11`} style={{ height: "30px" }}>
                      {arrayOfLength18.map((_, index) => (
                        <TableCell
                          key={`row-${row_index}-col-${index}-4`}
                          style={{
                            backgroundColor: "transparent",
                            padding: 0,
                            textAlign: "center",
                            border: "none",
                            cursor: "pointer",
                            width: "20px",
                            position: "relative",
                          }}
                        ></TableCell>
                      ))}
                    </TableRow>
                  </React.Fragment>
                );
              } else if (row_index === "16") {
                return (
                  <TableRow key={`row-${row_index}`} style={{ height: "30px" }}>
                    <StyledTableCell padding="checkbox">
                      <CustomCheckbox
                        size="small"
                        checked={
                          selectedCells[row_index]
                            ? Object.keys(selectedCells[row_index]).length ===
                              Object.keys(currentColumns).length
                            : false
                        }
                        onChange={() => handleRowCheckboxChange(row_index)}
                      />
                    </StyledTableCell>
                    {addMissingNumbers(Object.keys(currentColumns))?.map(
                      (column_index) => {
                        if (["7", "8", "10"].includes(column_index)) {
                          return (
                            <TableCell
                              key={`row-${row_index}-col-${column_index}-missing`}
                              style={{
                                backgroundColor: "transparent",
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                            ></TableCell>
                          );
                        } else if (column_index === "9") {
                          return (
                            <React.Fragment
                              key={`row-${row_index}-col-${column_index}`}
                            >
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-1`}
                                style={{
                                  backgroundColor: "transparent",
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                }}
                              >
                                {row_index}
                              </TableCell>
                              <TableCell
                                key={`row-${row_index}-col-${column_index}-2`}
                                style={{
                                  padding: 0,
                                  textAlign: "center",
                                  border: "none",
                                  cursor: "pointer",
                                  width: "20px",
                                  position: "relative",
                                  backgroundColor: "transparent",
                                }}
                              ></TableCell>
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <TableCell
                              key={`row-${row_index}-col-${column_index}`}
                              style={{
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                              onClick={() => {
                                handleCellClick(row_index, column_index);
                              }}
                            >
                              {/* Tooltip Wrapper */}
                              {(() => {
                                const cellData =
                                  (selectedCells[row_index] &&
                                    selectedCells[row_index][column_index]) ||
                                  currentColumns[column_index];
                                const cellPrice = cellData?.price;
                                return (
                                  <Tooltip
                                    title={`Price: ${
                                      cellPrice !== null && cellPrice !== undefined
                                        ? cellPrice
                                        : "Not available"
                                    }`}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          selectedCells[row_index] &&
                                          selectedCells[row_index][column_index]
                                            ? selectedCells[row_index][column_index].category
                                            : currentColumns[
                                                column_index as keyof typeof currentColumns
                                              ].category || "white",
                                        textAlign: "center",
                                        fontSize: "8px",
                                        margin: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "18px",
                                        height: "18px",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                      }}
                                    ></div>
                                  </Tooltip>
                                );
                              })()}
                            </TableCell>
                          );
                        }
                      }
                    )}
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={`row-${row_index}`} style={{ height: "30px" }}>
                    <StyledTableCell padding="checkbox">
                      <CustomCheckbox
                        size="small"
                        checked={
                          selectedCells[row_index]
                            ? Object.keys(selectedCells[row_index]).length ===
                              Object.keys(currentColumns).length
                            : false
                        }
                        onChange={() => handleRowCheckboxChange(row_index)}
                      />
                    </StyledTableCell>
                    {Object.keys(currentColumns)?.map((column_index) => {
                      if (column_index === "9") {
                        return (
                          <React.Fragment
                            key={`row-${row_index}-col-${column_index}`}
                          >
                            <TableCell
                              key={`row-${row_index}-col-${column_index}-5`}
                              style={{
                                backgroundColor: "transparent",
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                            >
                              {row_index}
                            </TableCell>
                            <TableCell
                              key={`row-${row_index}-col-${column_index}-6`}
                              onClick={() => {
                                handleCellClick(row_index, column_index);
                              }}
                              style={{
                                padding: 0,
                                textAlign: "center",
                                border: "none",
                                cursor: "pointer",
                                width: "20px",
                                position: "relative",
                              }}
                            >
                              {/* Tooltip Wrapper */}
                              {(() => {
                                const cellData =
                                  (selectedCells[row_index] &&
                                    selectedCells[row_index][column_index]) ||
                                  currentColumns[column_index];
                                const cellPrice = cellData?.price;
                                return (
                                  <Tooltip
                                    title={`Price: ${
                                      cellPrice !== null && cellPrice !== undefined
                                        ? cellPrice
                                        : "Not available"
                                    }`}
                                  >
                                    <div
                                      style={{
                                        backgroundColor:
                                          selectedCells[row_index] &&
                                          selectedCells[row_index][column_index]
                                            ? selectedCells[row_index][column_index].category
                                            : currentColumns[
                                                column_index as keyof typeof currentColumns
                                              ].category || "white",
                                        textAlign: "center",
                                        fontSize: "8px",
                                        margin: "auto",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "18px",
                                        height: "18px",
                                        borderTopLeftRadius: "5px",
                                        borderTopRightRadius: "5px",
                                      }}
                                    ></div>
                                  </Tooltip>
                                );
                              })()}
                            </TableCell>
                          </React.Fragment>
                        );
                      } else
                        return (
                          <TableCell
                            key={`row-${row_index}-col-${column_index}-7`}
                            style={{
                              padding: 0,
                              textAlign: "center",
                              border: "none",
                              cursor: "pointer",
                              width: "20px",
                              position: "relative",
                            }}
                            onClick={() => {
                              handleCellClick(row_index, column_index);
                            }}
                          >
                            {/* Tooltip Wrapper */}
                            {(() => {
                              const cellData =
                                (selectedCells[row_index] &&
                                  selectedCells[row_index][column_index]) ||
                                currentColumns[column_index];
                              const cellPrice = cellData?.price;
                              return (
                                <Tooltip
                                  title={`Price: ${
                                    cellPrice !== null && cellPrice !== undefined
                                      ? cellPrice
                                      : "Not available"
                                  }`}
                                >
                                  <div
                                    style={{
                                      backgroundColor:
                                        selectedCells[row_index] &&
                                        selectedCells[row_index][column_index]
                                          ? selectedCells[row_index][column_index].category
                                          : currentColumns[
                                              column_index as keyof typeof currentColumns
                                            ].category || "white",
                                      textAlign: "center",
                                      fontSize: "8px",
                                      margin: "auto",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      width: "18px",
                                      height: "18px",
                                      borderTopLeftRadius: "5px",
                                      borderTopRightRadius: "5px",
                                    }}
                                  ></div>
                                </Tooltip>
                              );
                            })()}
                          </TableCell>
                        );
                    })}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default SeatingPlanTableNew;