import { SalesManagementState, Order } from "../types";
import { salesManagementActionTypes } from "./action_types";

const initialState: SalesManagementState = {
  ordersList: [],
};
const { SET_ORDERS_LIST } = salesManagementActionTypes;


const salesManagementReducer = (
  state: SalesManagementState = initialState,
  action: salesManagementAction
): SalesManagementState => {
  switch (action.type) {
    case SET_ORDERS_LIST:
      return {
        ...state,
        ordersList: action.payload.data,
      };
    default:
      return state;
  }
};

export const setOrdersList = (data: Order[]) => ({
  type: SET_ORDERS_LIST,
  payload: {
    data,
  },
});

export type salesManagementAction = ReturnType<typeof setOrdersList> ;


export default salesManagementReducer;
