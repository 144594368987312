import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  useMediaQuery,
  useTheme,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useTranslation } from "react-i18next";

interface Order {
  orderId: string; // mapped from reserv_id
  date: string; // mapped from po_date
  event: string; // mapped from eventName_en
  customerName: string; // mapped from user_name
  soldTickets: number; // mapped from sold_ticket
  price: number; // mapped from proce
}

const SummaryCard = styled((props) => <Paper elevation={0} {...props} />)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
    border: `0px solid ${theme.palette.primary.main}`,
  })
);

const SummaryBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2),
  backgroundColor: "#DBE3FE",
  marginRight: theme.spacing(2),
  borderRadius: theme.spacing(1),
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: "#4D67EB",
  borderRadius: "50%",
  width: 50,
  height: 50,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#FFFFFF",
}));

const WhiteBackgroundBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  padding: theme.spacing(4),
  boxShadow: "none",
  width: "100%",
  margin: "0 auto",
  [theme.breakpoints.up("sm")]: {
    width: "83%",
  },
}));

const OrderListTable: React.FC = () => {
  const { ordersList } = useSelector(
    (state: RootState) => state?.salesManagementReducer
  );
  const [filter, setFilter] = useState<string>("Last Week");
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
const {t} = useTranslation();
  const handleFilterChange = (event: SelectChangeEvent) => {
    setFilter(event.target.value as string);
    setPage(0); // Reset to first page when filter changes
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Map the ordersList data to the Order interface
  const ordersData: Order[] = ordersList.map((order: any) => ({
    orderId: order.reserv_id,
    date: order.po_date,
    event: order.eventName_en,
    customerName: order.user_name,
    soldTickets: order.sold_ticket,
    price: parseFloat(order.proce),
  }));

  // Filter ordersData based on the selected filter
  const filteredOrdersData = ordersData.filter((order) => {
    const orderDate = new Date(order.date);
    const now = new Date();
    let dateLimit = new Date();

    if (filter === "Last Week") {
      dateLimit.setDate(now.getDate() - 7);
    } else if (filter === "Last Month") {
      dateLimit.setMonth(now.getMonth() - 1);
    } else if (filter === "Last Year") {
      dateLimit.setFullYear(now.getFullYear() - 1);
    }

    return orderDate >= dateLimit && orderDate <= now;
  });

  const summaryData = {
    Customer: {
      icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z" fill="#4D67EB"/>
      <path d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z" fill="#4D67EB"/>
      <path d="M19.9899 7.3401C20.1499 9.2801 18.7699 10.9801 16.8599 11.2101C16.8499 11.2101 16.8499 11.2101 16.8399 11.2101H16.8099C16.7499 11.2101 16.6899 11.2101 16.6399 11.2301C15.6699 11.2801 14.7799 10.9701 14.1099 10.4001C15.1399 9.4801 15.7299 8.1001 15.6099 6.6001C15.5399 5.7901 15.2599 5.0501 14.8399 4.4201C15.2199 4.2301 15.6599 4.1101 16.1099 4.0701C18.0699 3.9001 19.8199 5.3601 19.9899 7.3401Z" fill="#4D67EB"/>
      <path d="M21.9902 16.5899C21.9102 17.5599 21.2902 18.3999 20.2502 18.9699C19.2502 19.5199 17.9902 19.7799 16.7402 19.7499C17.4602 19.0999 17.8802 18.2899 17.9602 17.4299C18.0602 16.1899 17.4702 14.9999 16.2902 14.0499C15.6202 13.5199 14.8402 13.0999 13.9902 12.7899C16.2002 12.1499 18.9802 12.5799 20.6902 13.9599C21.6102 14.6999 22.0802 15.6299 21.9902 16.5899Z" fill="#4D67EB"/>
      </svg>
      ,
      value: filteredOrdersData.length,
    },
    Income: {
      icon: <div style={{ color: '#4D67EB' }}>AMD</div>
      ,
      value: filteredOrdersData.reduce((acc, order) => acc + order.price, 0),
    },
    // Balance: {
    //   icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    //   <path d="M7 2H6C3 2 2 3.79 2 6V7V21C2 21.83 2.94 22.3 3.6 21.8L5.31 20.52C5.71 20.22 6.27 20.26 6.63 20.62L8.29 22.29C8.68 22.68 9.32 22.68 9.71 22.29L11.39 20.61C11.74 20.26 12.3 20.22 12.69 20.52L14.4 21.8C15.06 22.29 16 21.82 16 21V4C16 2.9 16.9 2 18 2H7ZM5.97 14.01C5.42 14.01 4.97 13.56 4.97 13.01C4.97 12.46 5.42 12.01 5.97 12.01C6.52 12.01 6.97 12.46 6.97 13.01C6.97 13.56 6.52 14.01 5.97 14.01ZM5.97 10.01C5.42 10.01 4.97 9.56 4.97 9.01C4.97 8.46 5.42 8.01 5.97 8.01C6.52 8.01 6.97 8.46 6.97 9.01C6.97 9.56 6.52 10.01 5.97 10.01ZM12 13.76H9C8.59 13.76 8.25 13.42 8.25 13.01C8.25 12.6 8.59 12.26 9 12.26H12C12.41 12.26 12.75 12.6 12.75 13.01C12.75 13.42 12.41 13.76 12 13.76ZM12 9.76H9C8.59 9.76 8.25 9.42 8.25 9.01C8.25 8.6 8.59 8.26 9 8.26H12C12.41 8.26 12.75 8.6 12.75 9.01C12.75 9.42 12.41 9.76 12 9.76Z" fill="#4D67EB"/>
    //   <path d="M18.01 2V3.5C18.67 3.5 19.3 3.77 19.76 4.22C20.24 4.71 20.5 5.34 20.5 6V8.42C20.5 9.16 20.17 9.5 19.42 9.5H17.5V4.01C17.5 3.73 17.73 3.5 18.01 3.5V2ZM18.01 2C16.9 2 16 2.9 16 4.01V11H19.42C21 11 22 10 22 8.42V6C22 4.9 21.55 3.9 20.83 3.17C20.1 2.45 19.11 2.01 18.01 2C18.02 2 18.01 2 18.01 2Z" fill="#4D67EB"/>
    //   </svg>
    //   ,
    //   value: 5000, // You might want to calculate this based on your data
    // },
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box
      display="flex"
      minHeight="90vh"
      minWidth="100vw"
      bgcolor="#f0f2f5"
      padding={isSmallScreen ? 2 : 4}
    >
      <WhiteBackgroundBox>
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction={isSmallScreen ? "column" : "row"}
          spacing={isSmallScreen ? 2 : 0}
        >
          <Box>
            <Typography
              variant={isSmallScreen ? "h5" : "h5"}
              gutterBottom
              sx={{
                fontWeight: "bold",
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              {t("Order List")}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{
                textAlign: isSmallScreen ? "center" : "left",
              }}
            >
              {t("Summary of the order list that you have")}
            </Typography>
          </Box>
          {/* Filter Options */}
          <Box
            sx={{
              mt: isSmallScreen ? 2 : 0,
              minWidth: isSmallScreen ? "100%" : 200,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="filter-select-label">Filter</InputLabel>
              <Select
                labelId="filter-select-label"
                id="filter-select"
                value={filter}
                label="Filter"
                onChange={handleFilterChange}
              >
                <MenuItem
                  style={{
                    marginLeft: "1rem",
                  }}
                  value="Last Week"
                >
                  Last Week
                </MenuItem>
                <MenuItem
                  style={{
                    marginLeft: "1rem",
                  }}
                  value="Last Month"
                >
                  Last Month
                </MenuItem>
                <MenuItem
                  style={{
                    marginLeft: "1rem",
                  }}
                  value="Last Year"
                >
                  Last Year
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Box mt={4}>
          <Grid container spacing={isSmallScreen ? 2 : 4}>
            {Object.entries(summaryData).map(([key, data]) => (
              <Grid item xs={12} sm={4} key={key}>
                <SummaryCard>
                  <SummaryBox width={60}>
                    {data.icon}
                  </SummaryBox>
                  <Box>
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      sx={{ textAlign: isSmallScreen ? "left" : "left" }}
                    >
                      {key}
                    </Typography>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      sx={{
                        fontSize: isSmallScreen ? "1rem" : "1.2rem",
                        textAlign: isSmallScreen ? "left" : "left",
                      }}
                    >
                      {data.value}
                    </Typography>
                  </Box>
                </SummaryCard>
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Order Table */}
        <Box mt={4}>
          <Paper elevation={0}>
            <TableContainer
              sx={{
                maxHeight: 440,
                overflowX: isSmallScreen ? "auto" : "visible",
              }}
            >
              <Table stickyHeader aria-label="order table">
                <TableHead>
                  <TableRow>
                    <TableCell>ORDER</TableCell>
                    <TableCell>DATE</TableCell>
                    <TableCell>
                      <b>EVENT</b>
                    </TableCell>
                    <TableCell>CUSTOMER NAME</TableCell>
                    <TableCell>SOLD TICKET</TableCell>
                    <TableCell>PRICE</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOrdersData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((order) => (
                      <TableRow key={order.orderId} hover>
                        <TableCell>{order.orderId}</TableCell>
                        <TableCell>{order.date}</TableCell>
                        <TableCell>
                          <b>{order.event}</b>
                        </TableCell>
                        <TableCell>{order.customerName}</TableCell>
                        <TableCell>{order.soldTickets}</TableCell>
                        <TableCell>{order.price} AMD</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={filteredOrdersData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              sx={{
                overflowX: "auto",
              }}
            />
          </Paper>
        </Box>
      </WhiteBackgroundBox>
    </Box>
  );
};

export default OrderListTable;