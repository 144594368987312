export const data_example = {
  "1": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "2": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "3": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "4": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "5": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "6": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "7": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "8": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "9": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "10": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "11": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "12": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "13": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "14": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "15": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "7": { price: null, status: "open", ordered: "", category: "" },
    "8": { price: null, status: "open", ordered: "", category: "" },
    "9": { price: null, status: "open", ordered: "", category: "" },
    "10": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
  "16": {
    "1": { price: null, status: "open", ordered: "", category: "" },
    "2": { price: null, status: "open", ordered: "", category: "" },
    "3": { price: null, status: "open", ordered: "", category: "" },
    "4": { price: null, status: "open", ordered: "", category: "" },
    "5": { price: null, status: "open", ordered: "", category: "" },
    "6": { price: null, status: "open", ordered: "", category: "" },
    "11": { price: null, status: "open", ordered: "", category: "" },
    "12": { price: null, status: "open", ordered: "", category: "" },
    "13": { price: null, status: "open", ordered: "", category: "" },
    "14": { price: null, status: "open", ordered: "", category: "" },
    "15": { price: null, status: "open", ordered: "", category: "" },
    "16": { price: null, status: "open", ordered: "", category: "" },
  },
};
