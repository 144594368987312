import React from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import login_page_bg from "./../../Assets/login_page.png";
import { useNavigate } from "react-router-dom";

// Define styles using Material-UI makeStyles
const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    height: "100vh",
    width: "100vw",
    backgroundImage: `url(${login_page_bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "35%", // Adjust the width as needed
    height: "100%",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(4),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  },
  paper: {
    width: "80%",
    padding: theme.spacing(4),
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.85)", // Semi-transparent background
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
      backgroundColor: "rgba(255, 255, 255, 0.95)",
    },
  },
  backSymbol: {
    cursor: "pointer",
    marginBottom: theme.spacing(2),
  },
}));

interface Props {
  children: React.ReactNode;
  withBack: boolean;
}

const AuthPageWrapperComponent: React.FC<Props> = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { withBack, children } = props;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Paper elevation={6} className={classes.paper}>
          {withBack && (
            <div className={classes.backSymbol} onClick={() => navigate(-1)}>
              ←
            </div>
          )}
          {children}
        </Paper>
      </div>
    </div>
  );
};

export default AuthPageWrapperComponent;