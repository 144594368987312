import { EventsIcon, HallsIcon, SalesManagementIcon, SeatPlanIcon } from "./constants";
interface MenuItem {
  name: string;
  icon: React.ComponentType<any> | string;
  path: string;
  subItems?: MenuItem[];
}

type MenuItemsArray = MenuItem[];

const menu_items_arr: MenuItemsArray = [
  {
    name: 'Events',
    icon: EventsIcon,
    path: '/events'
  },
  {
    name: 'Halls',
    icon: HallsIcon,
    path: '/halls'
  },
  {
    name: 'Seating Plans',
    icon: SeatPlanIcon,
    path: '/seating-plans'
  },
  {
    name: 'Sales Management',
    icon: SalesManagementIcon,
    path: '/sales-management',
    subItems: [
      {
        name: 'Orders List',
        icon: "",
        path: '/order-list',
      },
      {
        name: 'Customers List',
        icon: "",
        path: '/customer-list',
      },
    ]
  }
];

export default menu_items_arr;
