import React, { useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import events_service from "../../services/events_service";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    paddingBottom: "3rem",
  },
  wrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    padding: "1rem",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  },
  headerCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  closeButton: {
    backgroundColor: "#4D67EBE6",
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: "#4D67EB",
    },
  },
  tableContainer: {
    border: "1px solid #ddd",
  },
  table: {
    borderCollapse: "collapse",
    "& td, & th": {
      border: "1px solid #ddd",
      padding: theme.spacing(1.5),
      textAlign: "center",
    },
  },
  tableTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  actionButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  customSwalPopup: {
    width: "30%",
    height: "30%",
  },
  customSwalContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    display: "flex",
    alignItems: "center",
    zIndex: 9999,
    fontSize: "16px",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  orderColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: theme.spacing(1),
  },
}));

const EventDetailsPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [infoList, setInfoList] = React.useState<any>({});
  const [closedList, setClosedList] = React.useState<any>({});
  const [eventName, setEventName] = React.useState<{
    name_en: string;
    name_am: string;
  }>({
    name_en: "",
    name_am: "",
  });

  const { t, i18n } = useTranslation();

  const getEventReport = () => {
    events_service
      .getEventReport({
        id: id,
      })
      .then((res) => {
        const infoListArr = res?.data?.status?.infolist;
        setInfoList(infoListArr);
        const closedData = res?.data?.status?.closedlist;
        setClosedList(closedData);
        setEventName({
          name_en: res?.data?.status?.eventName_en,
          name_am: res?.data?.status?.eventName_am,
        });
      });
  };

  useEffect(() => {
    getEventReport();
  }, [id]);

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  // Transform closedList into the same structure as the original tickets
  const closedTickets = Object.keys(closedList || {}).map((dateKey) => {
    const ticketsArray = closedList[dateKey];
    const total = ticketsArray.reduce(
      (sum: number, t: any) => sum + t.price,
      0
    );
    return {
      dateKey: dateKey,
      when: formatDate(dateKey),
      order: ticketsArray.map((ticket: any) => ({
        seat: `${t("Row")} ${ticket.row} ${t("Seat")} ${ticket.seat}`,
        price: ticket.price,
      })),
      total: total,
      comment: ticketsArray[0]?.comment || "",
    };
  });

  const handleRemoveClosedOrder = (commentDate: string) => {
    events_service
      .removeClosedOrders({
        event_id: id,
        commenttdate: commentDate,
      })
      .then(() => {
        MySwal.fire({
          icon: "success",
          title: t("Closed seat successfully opened"),
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: classes.customSwalPopup,
            container: classes.customSwalContainer,
          },
        });
        getEventReport();
      })
      .catch(() => {
        MySwal.fire({
          icon: "error",
          title: t("Failed to open closed seat"),
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            popup: classes.customSwalPopup,
            container: classes.customSwalContainer,
          },
        });
      });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <Card className={classes.headerCard} elevation={0}>
          <Typography variant="h5" style={{ color: "black" }}>
            {i18n.language === "en" ? eventName?.name_en : eventName?.name_am}
          </Typography>
          <Button
            variant="contained"
            className={classes.closeButton}
            size="medium"
            onClick={() => navigate("/events")}
          >
            {t("Back to events list")}
          </Button>
        </Card>

        {/* Statistics Table */}
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>{t("Price")}</TableCell>
                <TableCell colSpan={2}>{t("Ordered")}</TableCell>
                <TableCell colSpan={2}>{t("Free Tickets")}</TableCell>
                <TableCell colSpan={2}>{t("Discontinued")}</TableCell>
                <TableCell colSpan={2}>{t("Invitations")}</TableCell>
                <TableCell colSpan={2}>{t("All")}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t("Count")}</TableCell>
                <TableCell>{t("Sum")}</TableCell>
                <TableCell>{t("Count")}</TableCell>
                <TableCell>{t("Sum")}</TableCell>
                <TableCell>{t("Count")}</TableCell>
                <TableCell>{t("Sum")}</TableCell>
                <TableCell>{t("Count")}</TableCell>
                <TableCell>{t("Sum")}</TableCell>
                <TableCell>{t("Count")}</TableCell>
                <TableCell>{t("Sum")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(infoList || {}).map((priceKey) => {
                const info = infoList[priceKey];
                return (
                  <TableRow key={priceKey}>
                    <TableCell>{priceKey}</TableCell>
                    <TableCell>{info.Ordered}</TableCell>
                    <TableCell>{info.Ordered_price}</TableCell>
                    <TableCell>{info.Open}</TableCell>
                    <TableCell>{info.Open_price}</TableCell>
                    <TableCell>{info.Closed}</TableCell>
                    <TableCell>{info.Closed_price}</TableCell>
                    <TableCell>{info.Invitation}</TableCell>
                    <TableCell>{info.Invitation_price}</TableCell>
                    <TableCell>{info.Count}</TableCell>
                    <TableCell>{info.TotalPrice}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6" className={classes.tableTitle}>
          {t("Closed Seats List")}
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>{t("When")}</TableCell>
                <TableCell>{t("Order")}</TableCell>
                <TableCell>{t("Comment")}</TableCell>
                <TableCell>{t("Action")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {closedTickets.map((ticket, index) => (
                <TableRow key={index}>
                  <TableCell>{ticket.when}</TableCell>
                  <TableCell>
                    <Box className={classes.orderColumn}>
                      {ticket.order.map((item: any, idx: number) => (
                        <div key={idx}>
                          {item.seat} - ֏{item.price}
                        </div>
                      ))}
                      <Typography>
                        <strong>{t("Total:")}</strong> {ticket.order.length}{" "}
                        {t("Seats")} , ֏{ticket.total}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{ticket.comment}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      className={classes.actionButton}
                      size="small"
                      onClick={() => handleRemoveClosedOrder(ticket.dateKey)}
                    >
                      {t("Remove Order")}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default EventDetailsPage;
