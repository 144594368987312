// src/services/authService.ts
import { apiClient } from './api';

interface LoginResponse {
  full_name: string;
  jwt: string;
  status: {
    status: string;
    code: string;
    message: string;
  };
}

export const login = async (user_name: string, password: string): Promise<LoginResponse> => {
  const response = await apiClient.post<LoginResponse>('/login/user', { user_name, password });
  return response.data;
}